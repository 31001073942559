.searchInput {
	width: 400px;
	height: 40px;
	background-color: white;
	border-radius: 4px;
	border: 0;

	* {
		color: black !important;
	}
}

.searchResults_holder {
	width: 600px;
	max-width: 100%;
	background-color: white;
	padding: 10px;

	* {
		color: unset;
	}

	.category_holder {
		width: 100%;
		height: auto;
		margin-bottom: 20px;
		border-bottom: 1px solid grey;

		.category_title {
			padding: 0;
			margin: 0;
			font-size: 0.875rem;
			font-weight: 700;
			color: rgba(60,60,60,1);
			margin-bottom: 5px;
		}

		.category_results_holder {
			width: 100%;
			height: auto;

			.result_item {
				width: 100%;
				height: auto;
				display: flex;
				padding: 5px 0;
				cursor: pointer;

				* {
					color: black;
				}

				.item_id {
					padding: 0;
					margin: 0;
					font-size: 0.875rem;
					margin-right: 5px;
				}

				.item_title {
					padding: 0;
					margin: 0;
					font-size: 0.875rem;
					font-weight: 700;
				}
				
				.item_subTitle {
					padding: 0;
					margin: 0;
					font-size: 0.75rem;
					font-weight: 400;
				}

				.item_rightTitle {
					padding: 0;
					margin: 0;
					font-size: 0.75rem;
					font-weight: 400;
					text-align: right;
					margin-left: auto;
					align-self: flex-start;
				}

				&:hover {
					background-color: rgba(60,60,60,0.1);
				}
			}
		}
	}
}