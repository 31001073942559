:root {
	--primary_color: rgba(52, 73, 94, 1.0);
	--primary_color_hover: rgb(46, 62, 79);
	--secondary_color: rgba(44, 62, 80, 1.0);
	--secondary_color_hover: rgb(30, 42, 54);

	--success_color: rgba(39, 174, 96, 1.0);
	--success_color_hover: rgba(46, 204, 113, 1.0);

	--accept_color: rgba(138, 199, 219, 1);
	--accept_color_hover: rgb(129, 167, 180);

	--remove_color: rgba(192, 57, 43, 1);
	--remove_color_hover: rgb(154, 43, 31);

	--warning_color: rgba(192, 57, 43, 1);

	--task_color: rgba(33, 140, 116, 1.0);
	--meeting_color: var(--secondary_color);
	--phoneCall_color: var(--primary_color);
	--mail_color: rgba(132, 129, 122, 1.0);
	--lead_color: rgba(241, 196, 15, 1);

	--add_color: rgba(211, 84, 0, 1);
	--add_color_hover: rgba(172, 70, 1, 1);

	--global_content_left: 10px;
}

html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
p {
	padding: 0;
	margin: 0;
}

.btn {
	border: none;
	width: 100%;
	height: 40px;
	background-color: var(--primary_color);
	border-radius: 10px;
	font-size: 1rem;
	font-weight: 700;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: var(--secondary_color);
	}

	&.btn-sm {
		height: 30px;
		font-size: 0.875rem;
		font-weight: 500;
	}
}

.table_old {
	width: 100%;
	border-collapse: collapse;
	margin: 25px 0;
	font-size: 0.9em;
	font-family: sans-serif;
	min-width: 400px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

	thead {
		tr {
			background-color: var(--primary_color);
			color: #ffffff;
			text-align: left;
		}
	}

	th,
	td {
		padding: 6px 15px;
		text-align: left;
	}

	.alignRight {
		text-align: right;
	}

	tbody {
		tr {
			width: 100px;
			white-space: nowrap;
			border-bottom: thin solid #dddddd;

			&:nth-of-type(even) {
				background-color: #f3f3f3;
			}

			&:last-of-type {
				border-bottom: 2px solid var(--primary_color);
			}
		}

		th,
		td {
			padding: 6px 15px;
		}
	}

}

section {
	margin-bottom: 30px;
}

.MuiAutocomplete-popper {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5) !important;
}

.MuiDataGrid-root {
	.MuiDataGrid-cell:focus,
	.MuiDataGrid-cell:focus-within,
	.MuiDataGrid-columnHeader:focus,
	.MuiDataGrid-columnHeader:focus-within {
		outline: none;
	}
}