.invisible_menu_holder {
	width: 100%;
	height: 100px;
	position: relative;
	background-color: transparent;
}

.menu_holder {
	width: 100%;
	height: auto;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;

	.first_level {
		width: 100%;
		height: 50px;
		//background-color:rgba(230,230,230,1);
		background-color: var(--primary_color);
		display: flex;
		align-items: center;
		padding: 0 25px;

		&.mobile {
			overflow: auto;
		}

		.left_side {
			flex-shrink: 0;
			display: flex;
			align-items: center;

			.menu_item_holder {
				width: auto;
				position: relative;
				display: flex;
				align-items: center;

				.menu_item {
					&.active {
						&::after {
							background-color: white;
						}
					}
				}

				.subMenu_holder {
					width: auto;
					height: 40px;
					border-radius: 5px;
					background-color: rgba(190, 190, 190, 1.0);
					margin-left: 10px;
					padding: 0 5px;
					margin-right: 20px;

					* {
						color: rgba(0, 0, 0, 0.7) !important;
					}

					.menu_item {
						&.active {
							//background-color: var(--primary_color);
							&::after {
								background-color: var(--primary_color);
								bottom: 1px;
							}
						}
					}
				}
			}
		}

		.right_side {
			display: flex;
			align-items: center;
			margin-left: auto;
			flex-shrink: 0;

			.breadcrumbs_holder {
				margin-right: 20px;
			}

			.profileAvatar_holder {
				margin-left: 20px;
			}
		}

		* {
			color: white;
		}

		.logo_holder {
			width: 130px;
			height: auto;
			margin-right: 20px;
			position: relative;

			&.small {
				width: 30px;
				height: 20px;
				margin-right: 10px;
			}

			img {
				object-fit: contain;
			}

			h1 {
				padding: 0;
				margin: 0;
				font-size: 1rem;
				font-weight: 300;

				.bold {
					font-weight: 700;
				}
			}

			// @media screen and (max-width: 600px) {
			// 	width: 30px;
			// 	height: 20px;

			// 	&.small {

			// 	}
			// }
		}
	}

	.second_level {
		width: 100%;
		height: auto;
		background-color: rgba(190, 190, 190, 1.0);
		padding: 5px 10px 5px 151px;

		.menu_item {
			&.active {
				&::after {
					background-color: var(--primary_color);
					bottom: 1px;
				}
			}
		}
	}

	.menu_item {
		position: relative;

		&.active {
			&::after {
				content: '';
				width: 100%;
				height: 2px;
				position: absolute;
				left: 0;
				bottom: 0px;
			}
		}
	}
}

.content_holder {
	padding-left: 20px;
	padding-right: 20px;
}