.breadcrumbs {
	* {
		font-size: 0.875rem;
	}

	.home_icon_holder {
		display: flex;
		align-items: center;
		
		.home_icon {
			font-size: 1rem;
			padding: 0;
			margin: 0;
		}
	}
}