.third_level_controls_holder {
	width: 100%;
	height: auto;
	background-color: rgba(230, 230, 230, 1.0);
	padding: 5px 10px 5px 151px;
	display:flex;
	align-items:center;



	.menu_item {
		&.active {
			&::after {
				background-color: var(--primary_color);
				bottom: 1px;
			}
		}
	}
}